// ** Auth Endpoints
export default {
  loginEndpoint: `https://phpstack-666249-2511838.cloudwaysapps.com/api/auth/login`,
  registerEndpoint: 'http://localhost:8000/api/auth/register',
  refreshEndpoint: 'http://localhost:8000/api/auth/refresh-token',
  logoutEndpoint: 'http://localhost:8000/api/auth/logout',

  tokenType: 'Bearer',

  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
